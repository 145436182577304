<template>
    <div style="text-align: left;">
        <el-card shadow="never" style="margin-bottom: 25px;min-height: 698px;">
            <div slot="header" class="my-header">
                <div class="bold">视频分类</div>
                <el-button type="primary" size="mini" icon="el-icon-circle-plus" @click="addClassify">添加分类</el-button>
            </div>
            <template v-if="classifyList && classifyList.length">
                <div class="boxs">
                    <div v-for="(v,i) of classifyList" :key="i" class="items" @click="goto(v)">
                        <div class="item">
                            <div class="box">
                                <img v-if="v.videoAlbumPhoto":src="$oucy.ossUrl+v.videoAlbumPhoto" class="videoAlbumPhoto" />
                                <div v-else class="text-center notImg">
                                    <img src="@/assets/icon/notImg.png" class="img" alt="">
                                    <div class="f12 c-2">当前分类未上传视频</div>
                                </div>
                                <div class="line-1 videoAlbumName">{{v.videoAlbumName}}</div>
                            </div>
                            <div class="m-t-15">
                                <el-button type="" size="mini" @click="editClassify(v)"><i class="el-icon-edit-outline myIcon"></i>修改</el-button>
                                <el-button type="" size="mini" @click="delMessageBox('delEnterpriseVideoAlbum',v,i)"><i class="el-icon-delete myIcon"></i>删除</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div style="margin-bottom: 25px;text-align: center;margin-top: 50px;" v-else>
                <img src="@/assets/icon/notClassif.png" style="width:200px">
                <div class="f14 c-2 m-b-20">
                    当前您还没有视频分类哦！
                </div>
                <el-button type="default" size="mini" @click="addClassify">立即添加</el-button>
            </div>
        </el-card>
        <!-- 编辑视频分类 添加视频分类 -->
        <el-dialog width="500px" :title="classify.id?'编辑视频分类':'添加视频分类'" :visible.sync="classifyDialogFormVisible">
            <el-form :model="classify" ref="classifyForm" :rules="classifyFormRule" label-width="80px">
                <el-form-item label="名称" prop="videoAlbumName">
                    <el-input v-model="classify.videoAlbumName" autocomplete="off" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitClassifyForm">保 存</el-button>
                    <el-button @click="classifyDialogFormVisible = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import { enterpriseNewsAlbum, enterpriseNewsAlbumItem, enterprisevideoalbum } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "index",
    components: {},
    data() {
        return {
            // 下面是验证的
            classifyFormRule: {
                videoAlbumName: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                newsAlbumPhoto: [
                    { required: true, message: '请选择分类', trigger: 'blur' },
                ]
            },
            hasClass: true,
            hasList: true,
            enterpriseId: null,
            enterpriseNewsAlbumId: null,
            // 视频册
            classifyDialogFormVisible: !true,
            classifyList: [],
            classify: {
                videoAlbumName: null,
            },
            // 请求对象
            query: {
                enterpriseNewsAlbumId: null,
                enterpriseId: null,
                limit: 10,
                start: 0,

            },
            // 返回对象
            newsObj: {}

        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.getAllEnterpriseVideoAlbumsClient()
    },
    methods: {

        getAllEnterpriseVideoAlbumsClient() {
            enterprisevideoalbum.getAllEnterpriseVideoAlbumsClient({ enterpriseId: this.enterpriseId }).then(res => {
                this.classifyList = res
            })
        },

        // 点击保存分类册
        submitClassifyForm() {

            const me = this;
            me.$refs["classifyForm"].validate((valid) => {
                if (valid) {
                    this.classify.enterpriseId = this.enterpriseId
                    if (this.classify.id) {
                        this.updateEnterpriseVideoAlbum()
                    } else {
                        this.addEnterpriseVideoAlbum()
                    }
                }
            })
        },
        delMessageBox(fu, v, i, text = null) {
            this.$confirm(`确定要删除该视频分类[${v.videoAlbumName}]吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this[fu](v, i)
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });

            }).catch((err) => {
                console.log(err)
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 增加分类
        addClassify() {
            this.classify = {
                videoAlbumName: null,
                newsAlbumPhoto: null,
            }
            this.oPenClassifyDialogFormVisible()
        },
        // 编辑分类
        editClassify(v) {
            this.classify = Object.assign({}, v)
            this.oPenClassifyDialogFormVisible()
        },
        // 打开添加分类
        oPenClassifyDialogFormVisible() {
            this.classifyDialogFormVisible = true
            setTimeout(() => {
                this.$refs["classifyForm"].clearValidate()
            })
        },
        // 关闭添加分类
        closeClassifyDialogFormVisible(v) {
            this.classifyDialogFormVisible = false
        },

        // 新增企业视频册
        addEnterpriseVideoAlbum() {
            enterprisevideoalbum.addEnterpriseVideoAlbum(this.classify).then(res => {
                console.log(res)
                this.getAllEnterpriseVideoAlbumsClient()
                this.closeClassifyDialogFormVisible()
                this.$message({
                    type: 'success',
                    message: '新增成功!'
                });
            })
        },
        // 删除企业视频册
        delEnterpriseVideoAlbum(v, i) {
            enterprisevideoalbum.delEnterpriseVideoAlbum({ id: v.id, enterpriseId: this.enterpriseId }).then(res => {
                this.classifyList.splice(i, 1)
            })
        },
        // 修改企业视频册
        updateEnterpriseVideoAlbum() {
            enterprisevideoalbum.updateEnterpriseVideoAlbum(this.classify).then(res => {
                console.log(res)
                this.getAllEnterpriseVideoAlbumsClient()
                this.closeClassifyDialogFormVisible()
                this.$message({
                    type: 'success',
                    message: '修改成功!'
                });
            })
        },

        goto(v){
            this.$oucy.go('/enter/videoManagement?enterpriseVideoAlbumId='+v.id)
/*            this.$message({
                type: 'success',
                message: '开发中，敬请期待！'
            });*/
        },
        editnews(v) {
            this.$oucy.go('/enter/newsSave?id=' + v.id)
        },

    }
}
</script>
<style scoped>
.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

.boxs {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-left: -10px;
    margin-right: -10px;
}

.items {
    width: 16.666666666666668%;
    padding: 10px;
    box-sizing: border-box;
}

.item {
    box-sizing: border-box;
    width: 100%;

    text-align: center;
    /*border: 1px solid #EAEAEA;*/
    cursor: pointer;
}

.box {
    border-radius: 4px;
    background: rgb(242, 247, 255);
    /*background: linear-gradient(180deg, rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00) 79%, rgba(0, 0, 0, 0.34) 86%, rgba(0, 0, 0, 0.75));*/
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
}

.videoAlbumName {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background: linear-gradient(180deg, rgba(51, 51, 51, 0), rgba(51, 51, 51, .8));
}

.notImg {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);

    .img {
        width: 80px;
    }

    z-index: 1;
}
.videoAlbumPhoto{
    position: absolute;
    left: 50%;
    top: 50%;
    max-height: 100%;
    max-width: 100%;
    transform: translate(-50%, -50%);
}
@media screen and (max-width: 1350px) {
    .myIcon {
        display: none;
    }
}
</style>